/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import 'bootstrap/scss/bootstrap';
@import './app/styles/theme.scss';

html, body { height: 100%; width: 100%;}
body{ margin: 0; font-family:"Open Sans", sans-serif !important; }

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px "Open Sans", sans-serif ;
}

/* width */
::-webkit-scrollbar {
    width: 0.1px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $white;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $white;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $white;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }

  .mat-mdc-select-min-line{
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: #333334;
  }
  .mdc-notched-outline__notch{
    display: flex;
  }