@font-face {
    font-family: "poppins";
    src: url(./../../assets/fonts/Poppins/Poppins-Regular.ttf);
}


//font colors
$primary : #eb1f27;
$secondary : #FBFBE9;

$black : #050807;
$white : #ffffff;


.ff-poppins{
    font-family: "Poppins", sans-serif;
}

.ff-opensans{
    font-family: "Open Sans", sans-serif;
}

.headingColor{
    color:#344054;
}

.descColor{
    color: #667085;
}

.c060708{
    color:#060708;
}
.c707171{
    color:#707171;
}
.c060707{
    color: #060707;
}

// font weights
.fw-800{
    font-weight: 800;
}
.fw-700{
    font-weight: 700;
}
.fw-600{
    font-weight: 600;
}
.fw-500{
    font-weight: 500;
}
.fw-400{
    font-weight: 400;
}

// font-sizes
.fs-10{
    font-size: 10px;
}

.fs-12{
    font-size: 12px;
}
.fs-13{
    font-size: 13px;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-18{
    font-size: 18px;
}
.fs-20{
    font-size: 20px;
}
.fs-22{
    font-size: 22px;
}
.fs-24{
    font-size: 24px;
}
.fs-26{
    font-size: 26px;
}
.fs-28{
    font-size: 28px;
}
.fs-30{
    font-size: 30px;
}
.fs-32{
    font-size: 32px;
}
.fs-34{
    font-size: 34px;
}

@media only screen and (min-width:320px) and (max-width:375px){
    .fs-16{
        font-size: 14px;
    }
    .fs-12{
        font-size: 10px;
    }
    .fs-14{
        font-size: 12px;
    }
    .fs-18{
        font-size: 16px;
    }
    .fs-20{
        font-size: 18px;
    }
    .fs-22{
        font-size: 20px;
    }
    .fs-24{
        font-size: 22px;
    }
    .fs-34{
        font-size: 32px;
    }
}



@mixin text-family($font-family){
    font-family: "Poppins", sans-serif;
}

//removing bottom extra space

.mat-mdc-form-field-subscript-wrapper{
    display: none;
}

button:disabled{
    background-color: #FAB13B99 !important;
    color: #665f5f;
    cursor: auto;
}
.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control{
    font-size: 14px;
    font-weight: 400;
    color:black;
    @include text-family(open-sans);
}
.mat-mdc-text-field-wrapper {
    height: 40px !important;
    flex: auto;
    will-change: auto;
    align-items: center !important;
}

.ng-otp-input-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.otp-input{
    height: 50px !important;
    width: 50px !important;
    border-radius: 12px !important;
    margin-right: 0px !important;
}


.cursor-pointer{
    cursor: pointer;
}

    .mat-mdc-slide-toggle.mat-accent {
         --mdc-switch-selected-focus-state-layer-color: #F58930 !important; 
         --mdc-switch-selected-handle-color: #F58930 !important; 
         --mdc-switch-selected-hover-state-layer-color: #F58930 !important; 
         --mdc-switch-selected-pressed-state-layer-color: #F58930 !important; 
         --mdc-switch-selected-focus-handle-color: #F58930 !important; 
         --mdc-switch-selected-hover-handle-color: #F58930 !important; 
         --mdc-switch-selected-pressed-handle-color: #F58930 !important; 
         --mdc-switch-selected-focus-track-color: #F58930 !important; 
         --mdc-switch-selected-hover-track-color: #F58930 !important; 
         --mdc-switch-selected-pressed-track-color: #F58930 !important; 
         --mdc-switch-selected-track-color: #F58930 !important;
    }
